<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-data-table :headers="headers" :items="requests" :footer-props="{
                    'items-per-page-options': [5, 10, 15, 30, 50, , -1],
                }" :options="options" class="elevation-6 py-7" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat class="pb-5">
                            <v-toolbar-title><v-icon class="mr-5" color="primary">mdi-briefcase</v-icon>Request Form di Cambio</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.tools`]="{item}">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon color="primary" v-bind="attrs" v-on="on" :to="`/admin/formDiCambioDetails/${item.id}`">
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </template>
                            <span>View Form</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
export default {
    data() {
        return {
            options: {
                itemsPerPage: 10,
            },
            search: '',
            requests: [
                {   "id":485154,
                    "notification":"Cambio di adres",
                    "TipoDiCambioId":"",
                    "key":'',
                    "pdfFile":'',
                    "fam":"Guardia",
                    "FamNobo":"",
                    "promeNomber":"José",
                    "numberDiTelefon":"5548754102",
                    "email":"jguardia19@gmail.com",
                    "numberDiCedula":"96885525244",
                    "adresId":"",
                    "numberAdres": "",
                    "adresNoboId": "",
                    "numberAdresNobo": "",
                    "districto": "",
                    "estadoCivilBieu":"",
                    "estadoCivilNobo":"",
                    "motiboKierStopBijstand":"",
                    "motiboKierApoderaPersona":"",
                    "personaNotificacionDiCambio":"",
                    "naUndaHayaTrabou":"",
                    "fechaHayaTrabou":"",
                    "fileIdCartaTrabou":"",
                    "fileIdCartaDoctor":"",
                    "fileIdIdentificationBijstand":"",
                    "fileIdIdentificationApoderado":"",
                    "disclaimerChecked":false,
                },
                {   "id":978542,
                    "notification":"A haya trabou",
                    "TipoDiCambioId":"",
                    "key":'',
                    "pdfFile":'',
                    "fam":"Lumenier",
                    "FamNobo":"",
                    "promeNomber":"Elbert",
                    "numberDiTelefon":"99855632",
                    "email":"elbert-lumenier@xatech.com",
                    "numberDiCedula":"68599455",
                    "adresId":"",
                    "numberAdres": "",
                    "adresNoboId": "",
                    "numberAdresNobo": "",
                    "districto": "",
                    "estadoCivilBieu":"",
                    "estadoCivilNobo":"",
                    "motiboKierStopBijstand":"",
                    "motiboKierApoderaPersona":"",
                    "personaNotificacionDiCambio":"",
                    "naUndaHayaTrabou":"",
                    "fechaHayaTrabou":"",
                    "fileIdCartaTrabou":"",
                    "fileIdCartaDoctor":"",
                    "fileIdIdentificationBijstand":"",
                    "fileIdIdentificationApoderado":"",
                    "disclaimerChecked":false,
                },
            ],
            headers: [
                {
                    text: "ID",
                    value: "id",
                    align: "center",
                    class: "primary white--text px-0 mx-0",
                },
                {
                    text: "Notificacion di cambio(nan)",
                    value: "notification",
                    align: "center",
                    class: "primary white--text px-0 mx-0",
                },
                {
                    text: "Fam",
                    value: "fam",
                    align: "center",
                    class: "primary white--text px-0 mx-0",
                },
                {
                    text: "Prome Nomber(nan)",
                    value: "promeNomber",
                    align: "center",
                    class: "primary white--text px-0 mx-0",
                },
                {
                    text: "Email",
                    value: "email",
                    align: "center",
                    class: "primary white--text px-0 mx-0",
                },
                {
                    text: "Number di Identificacion (ID Nummer)",
                    value: "numberDiCedula",
                    align: "center",
                    class: "primary white--text px-0 mx-0",
                },
                {
                    text: "Tools",
                    value: "tools",
                    align: "center",
                    class: "primary white--text px-0 mx-0",
                },
            ],
        }
    }
}
</script>
  
<style></style>