var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-6 py-7",attrs:{"headers":_vm.headers,"items":_vm.requests,"footer-props":{
                'items-per-page-options': [5, 10, 15, 30, 50, , -1],
            },"options":_vm.options,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pb-5",attrs:{"flat":""}},[_c('v-toolbar-title',[_c('v-icon',{staticClass:"mr-5",attrs:{"color":"primary"}},[_vm._v("mdi-briefcase")]),_vm._v("Request Form di Cambio")],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item.tools",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","to":("/admin/formDiCambioDetails/" + (item.id))}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View Form")])])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }